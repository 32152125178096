.l-copyright {
  background: #3A3A3A;
  color: #fff;

  &__inner {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    margin: 0;
    font-size: 12px;

    a {
      color: #fff;
      padding: 0 10px;
    }
  }


}
