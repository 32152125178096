.l-form-steps {

  &__inner {
    margin: 0 auto;
    width: 95%;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }


  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 0 0 20%;

    &.is-completed h3 {
      color: $blue;
    }


    h3 {
      color: #C4C4C4;



      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 10px;
    }
  }

  &__checkmark {

  }

  &__progress {
    background: #C4C4C4;
    width: 100%;
    max-width: 820px;
    position: relative;
    height: 4px;
    top: 53px;
    margin: 0 auto;

    &-bar {
      background-color: $blue;
      position: relative;
      width: 0%;
      height: 4px;
      transition: all ease 0.2s;
    }
  }
}
