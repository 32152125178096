.case-submitted {
  text-align: center;

  h5 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  &__checkmark {
    padding-bottom: 40px;

    .checkmark {
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }

    .checkmark {
      animation: fill 0.4s ease-in-out 0.6s both, scale 0.2s ease-in-out 1s both;
    }

    .checkmark__circle {
      animation: stroke 0.6s $curve both;
    }

    .checkmark__check {
      animation: stroke 0.3s $curve 1s forwards;
    }
  }
}
