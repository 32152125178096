.home-block {


  .form {
      margin-top: 0px;
    }



  h3 {
    color: $blue;
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 0;
  }

  .form-control {
    height: 65px;
    line-height: 65px;
    font-weight: bold;
    margin-bottom: 20px;

    &::placeholder {
      color: #C4C4C4;
    }
  }

  .l-hero__button {
    padding: 0.5em 2em;
    box-shadow: 0 0 22px 10px rgba(0,0,0,.05);
  }

  .form-group {
    align-items: center;
    justify-content: center;
  }

  .form-wrapper {

    padding: 30px 80px;
    width: 870px;
    margin-bottom: 50px;

  }
}
