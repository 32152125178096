$blue: #01ADEF;
$red: #B72226;
$grey: #4D4D4D;
$blue2: #01ADEF;

.row {
    margin-bottom: 0;
}

.btn-primary {
  background: $blue;
  border-color: $blue;
}

body {
  color: $grey;
  font-family: proxima-nova, sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: proxima-nova, sans-serif;
}

.l-form-area {
  background: linear-gradient(0deg,#fff 0,#f4f3f3 100%);
  margin-top: 250px;
}

.btn i {
  top: 1px;
  position: relative;
}

.btn-next i,
.btn-prev i {
  transition: all ease 0.3s;
}

.btn-next:hover i {
  transform: translateX(5px);
}

.btn-prev:hover i {
  transform: translateX(-5px);
}

.copyright {
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 10px;

  p {
    margin-bottom: 0;
  }
}

.next-frame {
  background-image: url('../img/dummy.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  height: 1511px;
  display: block;
}
