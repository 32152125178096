$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.l-form-steps__checkmark {
  background: #c4c4c4;
  border-radius: 100%;
}



.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $blue;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: $blue;
  fill: none;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}


@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 100px $blue;
  }
}

.l-form-steps__item.is-current-step {


h3 {
    color: $blue;
  }

  .checkmark {
    animation: fill .4s ease-in-out .4s both;
  }

  .checkmark__circle {
    animation: stroke .6s $curve both;
  }
}

.l-form-steps__item.is-completed {
  .checkmark {
    animation: scale .1s ease-in-out .1s both;
  }

  .checkmark__circle {
    fill: $blue;

  }
  .checkmark__check {
    animation: stroke .3s $curve .1s forwards;
  }
}
