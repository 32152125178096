.l-disclaimer {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #E6E6E6;
  padding-top: 60px;
  margin-top: 60px;
  padding-bottom: 60px;

  &__item {
    flex: 0 0 48%;

    img {
      margin-top: 5px;
      max-width: 100%;
      min-width: 100%;
    }
  }

  h3 {
    margin-bottom: 30px;
  }
}
