.l-process {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,243,243,1) 100%);
  padding-top: 50px;

  &__title {
    font-size: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 500;
    padding-bottom: 30px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    flex: 0 0 23%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__icon {
    width: 100px;
    height: 100px;
  }

  &__text {
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.3;
  }

  &__titles {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
