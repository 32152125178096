.l-footer {
  background-color: #4D4D4D;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
  }

  &__logo {
    width: 200px;
  }

  &__pci {
    width: 100px;
  }

  &__item {
    flex: 0 0 45%;
    color: #fff;

    h3 {
      font-size: 22px;
      font-weight: bold;
    }
  }

  &__recent-posts {
    margin: 0;
    padding: 0;
    padding-bottom: 30px;

    & li {
    list-style: none;
    }

    & a {
      color: #fff;
      display: block;
      border-bottom: 1px solid #fff;
      padding: 5px 0;

      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }
}
