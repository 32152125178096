.l-header {
  width: 100%;
  display: block;
  border-top: 30px solid $blue;
  padding-top: 30px;
  background-color: #fff;

  &__inner {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    justify-content: space-between;
  }

  &__logo {
    width: 243px;
  }

  &__menu {


    & ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    & li {

    }

    & a {
      font-size: 16px;
      color: #707070;
      padding: 0 15px;

      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }

  }
}
