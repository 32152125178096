.l-hero {
  background-color: #fff;
  padding-bottom: 90px;



  &__inner {
    text-align: center;
  }

  &__title {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.1;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 40px;
    max-width: 990px;
    padding-top: 70px;
    color: $grey;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: -0.015em;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px;
    max-width: 900px;
  }

  &__button {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    background: $red;
    border: none;
    appearance: none;
    width: 100%;
    max-width: 360px;
    padding:0.8em 2em;
    box-shadow: 0px 0px 42px 10px rgba(0,0,0,0.2);

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 42px 20px rgba(0,0,0,0.1);
      color: #fff !important;
    }
  }
}
