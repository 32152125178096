.form {
    margin-top: -250px;

    
  &__content {
    box-shadow: 0px 0px 38px -3px rgba(0,0,0,0.08);
    padding: 60px 40px;
  }

  &-group p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-bottom: .5rem;
  }

  &-control {
    font-weight: 500;
  }

  &-flex{
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .form-group--50 {
      flex: 0 0 48%;
    }

    .form-group--100 {
      flex: 0 0 100%;
    }
  }

  &-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 780px;
    margin-top: 60px;
    margin-bottom: 160px;
    background-color: #fff;
  }

  h2, h3 {
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    margin-bottom: 0.8em;
    padding-bottom: 0.8em;
  }

  h5 {
    font-weight: bold;
  }
}

.choices {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    padding: 20px;

    & .btn {
      font-weight: 500;
      font-size: 1.2rem;
      width: 250px;
    }
  }
}

label.inline {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  flex: 0 0 40%;
}

label {
  font-weight: 400;
}

.form-control-lg, .input-group-lg>.form-control, .input-group-lg>.input-group-append>.btn, .input-group-lg>.input-group-append>.input-group-text, .input-group-lg>.input-group-middle>.input-group-text, .input-group-lg>.input-group-prepend>.btn, .input-group-lg>.input-group-prepend>.input-group-text {
    padding: 0.65rem 1rem;
  }

.custom-controls-inline {
  display: flex;

  .custom-control {
    margin-right: 20px;
  }
}

.form-group-flex {
  display: flex;

  p {
    font-weight: 500;
    margin: 0;
  }

  .custom-controls-inline {
    margin-left: 20px;
  }

}

h5 {
  font-weight: 500
}

.custom-control-flex {
  display: flex;
  margin-bottom: 20px;

  label {
    font-weight: 500;
  }

  label input {
    display: inline;
    width: 115px;
  }

  .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
}

.custom-radio .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%)
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 50%;
  transform: translateY(-50%)
}

.custom-control-label::before,
.custom-control-label::after,
.custom-radio .custom-control-label::after {
  transition: all 0.125s;
}

.custom-control-label:hover::before {
  border-color: $blue;
}
